import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomAccordion,
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  CustomTabs,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import { black900, red300 } from "../../../shared/Helpers/colors";
import { ButtonWrapper, Container } from "../../../components/StyledComponents";
import CredentialedBCBA from "./credentialedBCBA";
import NotCredentialedBCBA from "./notCredentialedBCBA";
import DefaultBCBA from "./defaultBCBA";
import BT from "./BT";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  clearTherapists,
  getClient,
} from "../../../redux/State/clientSlice/clientSlice";
import {
  assignUsersToClient,
  getDefaultBCBA,
  getDefaultBCBAOptions,
  getTherapists,
  updateDefaultBCBA,
} from "../../../redux/State/clientSlice/userClientSlice";
import { UpdateDefaultBCBAProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";

const tabHeaders = [`BCBA - credentialed`, `BCBA - not credentialed`, `BT`];
const panels = [CredentialedBCBA, NotCredentialedBCBA, BT];

const Assign = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();
  const { clientId } = useParams();

  const therapists = useSelector((state) => state.userClient.therapists);
  const clientInfo = useSelector((state) => state.client.client);

  useEffect(() => {
    if (!!therapists || !clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, therapists, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    if (!!clientInfo.fullName) return;
    dispatch(getClient(clientId));
  }, [clientId, clientInfo, dispatch]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        className="marginBottom16"
      >
        <Text
          title={`Assign Providers for ${clientInfo.fullName}`}
          size="largeBold"
        />
        <DefaultBCBA />
      </div>
      {screenWidth > 768 ? (
        <CustomTabs headers={tabHeaders} panels={panels} />
      ) : (
        tabHeaders.map((header, index) => (
          <CustomAccordion
            key={index}
            HeaderContent={() => <Text title={header} size="smallBold" />}
            Content={panels[index]}
          />
        ))
      )}
      <SaveAssigningChanges />
    </Container>
  );
};

const SaveAssigningChanges = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );
  const billingUserId = useSelector((state) => state.userClient.billableUserId);
  const therapists = useSelector((state) => state.userClient.therapists);
  const loading = useSelector((state) => state.userClient.loading);

  const assignedUsers = selectedUsers.filter((x) => x.isAssigned);
  const unAssignedUsers = selectedUsers.filter(
    (x) => !x.isAssigned && therapists?.find((y) => y.id === x.id)
  );

  const userIds = [...assignedUsers, ...unAssignedUsers];

  const [assingConfirm, setAssignConfirm] = useState<boolean>(false);

  const openHandler = () => setAssignConfirm(true);
  const closeHandler = () => setAssignConfirm(false);

  const assginTherapistsHandler = () => {
    if (!clientId) return;

    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    dispatch(
      assignUsersToClient({
        userIds,
        clientId,
        billingUserId,
        endDate,
        startDate,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) {
          toast("Something went wrong");
          return;
        }
        dispatch(getDefaultBCBA(clientId));
        dispatch(getDefaultBCBAOptions(clientId));
        openHandler();
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      <AssignInfo />
      <div style={{ width: "350px", textAlign: "center" }}>
        <Text
          title={
            "Changes from all tabs will be saved. Adjust provider selections as needed before saving."
          }
          textColor={red300}
          size="tinyBold"
          className="padding8"
        />
        <CustomButton
          title="Save"
          onClick={assginTherapistsHandler}
          loading={loading}
        />
        <CustomAlert
          open={assingConfirm}
          onClose={closeHandler}
          title="Set Default BCBA"
          Content={({ onClose }) => <DefaultBCBAConfirm onClose={onClose} />}
        />
      </div>
    </div>
  );
};

const AssignInfo = () => {
  const therapists = useSelector((state) => state.userClient.therapists);
  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );

  const assignedUsers = selectedUsers.filter((x) => x.isAssigned);
  const unAssignedUsers = selectedUsers.filter(
    (x) => !x.isAssigned && therapists?.find((y) => y.id === x.id)
  );
  const supervisorsUpdated = assignedUsers.filter((x) =>
    therapists?.find((z) => z.id === x.id)
  );

  return (
    <div style={{ width: "400px" }} className="padding16">
      <Text
        title={`Assigned Providers (${assignedUsers.length}):`}
        size="mediumBold"
      />
      {assignedUsers
        .filter((x) => !therapists?.find((z) => z.id === x.id))
        .map((y) => (
          <Text title={y.fullName} />
        ))}
      <Text title={`Supervisor updated:`} size="tinyBold" />
      {supervisorsUpdated.map((y) => (
        <Text title={y.fullName} size="tiny" />
      ))}
      <Text
        title={`Unassigned Providers (${unAssignedUsers.length}): `}
        size="mediumBold"
      />
      {unAssignedUsers.map((y) => (
        <Text title={y.fullName} />
      ))}
    </div>
  );
};

const today = dayjs();

const DefaultBCBAConfirm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [userId, setUserId] = useState<string>("");
  const [startDateValue, setStartDateValue] = useState<Dayjs>(today);

  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);
  const defaultBCBAoptions = useSelector(
    (state) => state.userClient.defaultBCBAOptions
  );

  const updateDefaultBCBAHandler = () => {
    if (!userId || !clientId) return;
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(today);
    const data: UpdateDefaultBCBAProperties = {
      clientId,
      data: { userId, startDate, endDate },
    };
    dispatch(updateDefaultBCBA(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        navigateToAllocateHandler();
      })
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const changeDefaultBCBAHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    setUserId(id);
  };

  const navigateToAllocateHandler = () => {
    dispatch(clearTherapists());
    navigate(`/clients/client/allocate-authorization/${clientId}`);
  };

  return (
    <div>
      {defaultBCBAoptions?.map((x) => (
        <div style={{ display: "flex" }}>
          <CustomCheckBox
            item={{
              id: x.id,
              checked: !!userId ? userId === x.id : defaultBCBA?.id === x.id,
            }}
            onChange={changeDefaultBCBAHandler}
          />
          <Text
            title={x.fullName}
            textColor={defaultBCBA?.id === x.id ? red300 : black900}
          />
        </div>
      ))}
      <Picker.CustomDate
        label="Start Date"
        value={startDateValue}
        onChange={startDateHandler}
        disableFuture={true}
      />
      <ButtonWrapper className="marginTop16">
        <CustomButton
          title="Save"
          onClick={updateDefaultBCBAHandler}
          // loading={loading}
        />
        <CustomButton
          secondaryButton
          title="Allocate Hours"
          onClick={navigateToAllocateHandler}
        />
      </ButtonWrapper>
    </div>
  );
};

export default Assign;
